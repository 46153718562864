@font-face {
  font-family: 'Montserrat-Black';
  src: url('./fonts/Montserrat/Montserrat-Black.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('./fonts/Montserrat/Montserrat-SemiBold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./fonts/Montserrat/Montserrat-Bold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./fonts/Montserrat/Montserrat-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Thin';
  src: url('./fonts/Montserrat/Montserrat-Thin.otf');
  font-weight: normal;
  font-style: normal;
}
