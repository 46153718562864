@import './fonts';

//Colors
$color-background-home: #F6F6F6;

$color-black: black;
$color-green: #5DBB47;
$color-red: #BB4747;
$color-light-yellow: #F9F1D4;
$color-yellow: #FFCD33;
$color-dark-yellow: #81681B;
$color-gold: #D4AF3D;
$color-requested: #B9A35C;
$color-grey: #A8A8A8;
$color-blue: #2392BC;


//Fonts
$font-black: 'Montserrat-Black', sans-serif;
$font-black-italic: 'Montserrat-BlackItalic', sans-serif;
$font-bold: 'Montserrat-Bold', sans-serif;
$font-bold-italic: 'Montserrat-BoldItalic', sans-serif;
$font-extra-bold: 'Montserrat-ExtraBold', sans-serif;
$font-extra-bold-italic: 'Montserrat-ExtraBoldItalic', sans-serif;
$font-extra-light: 'Montserrat-ExtraLight', sans-serif;
$font-extra-light-italic: 'Montserrat-ExtraLightItalic', sans-serif;
$font-italic: 'Montserrat-Italic', sans-serif;
$font-light: 'Montserrat-Light', sans-serif;
$font-light-italic: 'Montserrat-LightItalic', sans-serif;
$font-medium: 'Montserrat-Medium', sans-serif;
$font-medium-italic: 'Montserrat-MediumItalic', sans-serif;
$font-regular: 'Montserrat-Regular', sans-serif;
$font-semi-bold: 'Montserrat-SemiBold', sans-serif;
$font-semi-bold-italic: 'Montserrat-SemiBoldItalic', sans-serif;
$font-thin: 'Montserrat-Thin', sans-serif;
$font-thin-italic: 'Montserrat-ThinItalic', sans-serif;

//Sizes
$xxl_devices: 1281px;

//Font-presets
.font-caption {
  font-size: 0.75rem;
  color: #A8A8A8;
  font-family: $font-regular;
}

.font-pill {
  font-family: $font-semi-bold;
  font-size: 0.7rem;
}

.font-primary {
  font-size: 0.85rem;
  font-family: $font-regular;
}

.font-secondary {
  font-size: 0.9rem;
  font-family: $font-regular;
}

.font-tertiary {
  font-size: 0.75rem;
  font-family: $font-regular;
}

.table-header-font {
  font-size: 0.8rem;
  color: $color-grey;
}

.font-grey {
  color: $color-grey;
}

.table-featured-font {
  font-size: 0.9rem;
  color: $color-blue;
  font-family: $font-bold;
}

.font-bold {
  font-family: $font-bold;
}

.font-title {
  font-size: 1.2rem !important;
  font-family: $font-bold !important;
}

